import { map, startWith } from "rxjs/operators";
import {
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { Component, forwardRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

//3rd party deps
import {
  MDBModalRef,
  MDBModalService,
  MdbSelectComponent,
  PopoverDirective,
} from "ng-uikit-pro-standard";

//internal services
import { UserServicesService } from "src/app/modules/user-management/services/user-services.service";
import { CoolLocalStorage } from "@angular-cool/storage";
import { XrPlatformRestService } from "src/app/services/rest/xr-platform/xr-platform-rest.service";
import { Observable, Subject } from "rxjs";

import {
  faPlusSquare,
  faMinus,
  faTimes,
  faMagnifyingGlass,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { DOCUMENT, TitleCasePipe } from "@angular/common";
import { NotificationsService } from "src/app/services/utilities/notifications.service";
import { NgxCSVParserError, NgxCsvParser } from "ngx-csv-parser";

@Component({
  selector: "app-manage-phoneme-lists",
  templateUrl: "./manage-phoneme-lists.component.html",
  styleUrls: ["./manage-phoneme-lists.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MdbSelectComponent),
      multi: true,
    },
    TitleCasePipe
  ],
})
export class ManagePhonemeListsComponent implements OnInit {
  selectControl = new UntypedFormControl("1");

  @ViewChild("popOverTrigger") popOverTrigger: PopoverDirective;
  @ViewChild("popOverTriggerTop") popOverTriggerTop: PopoverDirective;

  @ViewChild('fileImportInput') fileImportInput: any;

  //icons
  public faPlusSquare = faPlusSquare;
  public faMinus = faMinus;
  public faTimes = faTimes;
  public faMagnifyingGlass = faMagnifyingGlass;
  public faCirclePlus = faCirclePlus;

  options = [
    { value: "1", label: "Option 1" },
    { value: "2", label: "Option 2" },
    { value: "3", label: "Option 3" },
  ];

  //incoming
  public clientSettings: any;
  public skillID: number;

  //loading
  public formLoading: boolean = true;

  //persistent
  public token: string;
  public notification: any;
  public formState: string = "pending";
  public groupType: string = "none";
  public isClean: boolean = true;

  //csv
  public csvRecords: any[] = [];

  //copy
  public formTitle: string = "Add Word List";
  public btnLabel: {
    close: string;
    main: string;
    reset: string;
    retry: string;
  } = {
      close: "Close",
      main: "Add Word List",
      reset: "Add More Words",
      retry: "Retry",
    };

  //form related
  public managePhonemesForm: UntypedFormGroup;
  public wordListForm: UntypedFormGroup;

  //for modals
  public manageGroupRosterFrame: MDBModalRef;
  public restoreFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  //data back to parent
  private outgoing: Subject<any> = new Subject();
  constructor(
    public manageSystemUsersFrame: MDBModalRef,
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private _notificationService: NotificationsService,
    private ngxCsvParser: NgxCsvParser,
  ) { }

  ngOnInit(): void {

    this.retrieveToken();

    //instantiate forms
    this.wordListForm = new UntypedFormGroup({
      list_name: new UntypedFormControl("", Validators.required),
      list_description: new UntypedFormControl(""),
    });

    this.managePhonemesForm = new UntypedFormGroup({
      users: new UntypedFormArray([]),
    });

    this.addPhonemeFields();

  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  public fileChangeListener($event: any): void {
    console.log("fileChangeListener", $event);

    let notification = this._notificationService.savingNotification("Parsing CSV file");

    const files = $event.srcElement.files;
    this.ngxCsvParser
      .parse(files[0], {
        header: true,
        delimiter: ',',
        encoding: 'utf8'
      })
      .pipe()
      .subscribe(
        (result: Array<any>) => {
          console.log('Result', result);
          this._notificationService.clearNotification(notification);
          this._notificationService.successNotification("CSV file parsed successfully, please review user the user entries and then click the 'Add Users' button", 5000);

          //remove any existing form fields that are empty
          let emptyFields = this.managePhonemeFieldsGroups.filter((field) => {
            return field.value.full_word === "";
          });

          if (emptyFields.length > 0) {
            emptyFields.forEach((field) => {
              let index = this.managePhonemesFormArray.controls.indexOf(field);
              this.managePhonemesFormArray.removeAt(index);
            });
          }

          if (Array.isArray(result) && result.length > 0) {

            result.forEach((entry) => {
              this.addPhonemeFields(entry);
            });

          }

          //trigger form validation
          this.isClean = false;
          this.managePhonemesForm.markAllAsTouched();

        },
        (error: NgxCSVParserError) => {
          console.log('Error', error);
          this._notificationService.errorNotification("Error parsing CSV file, please check the file and try again");
        }
      );
  }

  get list_name() {
    return this.wordListForm.get("list_name");
  }

  get managePhonemesFormControls() {
    return this.managePhonemesForm.controls;
  }
  get managePhonemesFormArray() {
    return this.managePhonemesFormControls.users as UntypedFormArray;
  }

  get managePhonemeFieldsGroups() {
    return this.managePhonemesFormArray.controls as UntypedFormGroup[];
  }

  public addPhonemeFields(values?) {
    if (values === undefined) values = null;

    /**
     * New form array with following fields, none are currently required
     * - full_word: text
      - phoneme_1: text
      - phoneme_type_1: text
      - phoneme_spelling_1: text
      - is_unpredictable_1: text
      - has_silent_e_1: text
      - phoneme_2: text
      - phoneme_type_2: text
      - phoneme_spelling_2: text
      - is_unpredictable_2: text
      - has_silent_e_2: text
      - phoneme_3: text
      - phoneme_type_3: text
      - phoneme_spelling_3: text
      - is_unpredictable_3: text
      - has_silent_e_3: text
      - phoneme_4: text
      - phoneme_type_4: text
      - phoneme_spelling_4: text
      - is_unpredictable_4: text
      - has_silent_e_4: text
     * 
     */

    let newFormArray = new UntypedFormGroup({
      full_word: new UntypedFormControl(values ? values.full_word : ""),
      phoneme_1: new UntypedFormControl(values ? values.phoneme_1 : ""),
      phoneme_type_1: new UntypedFormControl(values ? values.phoneme_type_1 : ""),
      phoneme_spelling_1: new UntypedFormControl(values ? values.phoneme_spelling_1 : ""),
      is_unpredictable_1: new UntypedFormControl(values ? values.is_unpredictable_1 : ""),
      has_silent_e_1: new UntypedFormControl(values ? values.has_silent_e_1 : ""),
      phoneme_2: new UntypedFormControl(values ? values.phoneme_2 : ""),
      phoneme_type_2: new UntypedFormControl(values ? values.phoneme_type_2 : ""),
      phoneme_spelling_2: new UntypedFormControl(values ? values.phoneme_spelling_2 : ""),
      is_unpredictable_2: new UntypedFormControl(values ? values.is_unpredictable_2 : ""),
      has_silent_e_2: new UntypedFormControl(values ? values.has_silent_e_2 : ""),
      phoneme_3: new UntypedFormControl(values ? values.phoneme_3 : ""),
      phoneme_type_3: new UntypedFormControl(values ? values.phoneme_type_3 : ""),
      phoneme_spelling_3: new UntypedFormControl(values ? values.phoneme_spelling_3 : ""),
      is_unpredictable_3: new UntypedFormControl(values ? values.is_unpredictable_3 : ""),
      has_silent_e_3: new UntypedFormControl(values ? values.has_silent_e_3 : ""),
      phoneme_4: new UntypedFormControl(values ? values.phoneme_4 : ""),
      phoneme_type_4: new UntypedFormControl(values ? values.phoneme_type_4 : ""),
      phoneme_spelling_4: new UntypedFormControl(values ? values.phoneme_spelling_4 : ""),
      is_unpredictable_4: new UntypedFormControl(values ? values.is_unpredictable_4 : ""),
      has_silent_e_4: new UntypedFormControl(values ? values.has_silent_e_4 : ""),
      phoneme_5: new UntypedFormControl(values ? values.phoneme_5 : ""),
      phoneme_type_5: new UntypedFormControl(values ? values.phoneme_type_5 : ""),
      phoneme_spelling_5: new UntypedFormControl(values ? values.phoneme_spelling_5 : ""),
      is_unpredictable_5: new UntypedFormControl(values ? values.is_unpredictable_5 : ""),
      has_silent_e_5: new UntypedFormControl(values ? values.has_silent_e_5 : ""),
    });

    newFormArray.valueChanges.subscribe((value) => {
      this.isClean = false;
    });

    this.managePhonemesFormArray.push(newFormArray);

    this.formLoading = false;

  }

  public removePhonemeField(index) {
    this.managePhonemesFormArray.removeAt(index);
  }

  public async addWordList() {
    this.formState = "processing";
    this.notification = this._notificationService.savingNotification(
      `Adding Words`
    );
    let formValues = this.managePhonemesForm.value;
    let listValues = this.wordListForm.value;
    console.log("formValues in addWordList", formValues);

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };
    const options = {
      headers: headers,
    };

    //@todo: update this value to words instead of users
    let body = {
      "label": listValues.list_name,
      "description": listValues.list_description,
      "original_filename": "tbd",
      "words": formValues.users
    }

    let submitWords = await this.submitWordList(body, options);
    let finalWords = [];
    let outgoingData = {
      phoneme_list: submitWords.phoneme_list,
    };
    let errors = false;
    this.finalActions(outgoingData, errors);
  }

  private async submitWordList(body, options) {
    const url = `/authoring/skill/${this.skillID}/phoneme-list`;
    return this._xrPlatformRestService.restfulAPIQuery(url, "post", body, options).toPromise();
  }

  private finalActions(data, errors) {
    if (!errors) {
      this._notificationService.successNotification(
        `All words added successfully`
      );
    } else {
      this._notificationService.errorNotification(
        `Some words could not be added`
      );
    }

    this.outgoing.next({
      data: data,
      action: "add",
    });

    this.formState = "complete";
    this.closeModal(true);
  }

  public showingWarning() {
    this.managePhonemesForm.markAllAsTouched();
  }

  public closeModal(overrule?) {
    if (overrule === undefined) overrule = false;

    if (!this.isClean && !overrule) return false;

    this.manageSystemUsersFrame.hide();
  }

  public closePopOvers() {
    if (this.popOverTrigger !== undefined) this.popOverTrigger.hide();
    if (this.popOverTriggerTop !== undefined) this.popOverTriggerTop.hide();
  }

  public modalClick(event) {


    let isPopover = false;

    //check to see if this click is coming from the pop up triggers
    let target = event.target;
    let parent = target.parentElement;

    //see if fa-xmark is in the parents class list (indicates fa-icon xmark)
    if (parent !== null) {
      if (parent.classList.contains("fa-xmark")) isPopover = true;
      if (parent.classList.contains("cancel-alert")) isPopover = true;
    }

    //see if target class list has the cancel-alert class
    if (target.classList.contains("cancel-alert")) isPopover = true;

    //see if target class list has the fa-xmark class
    if (target.classList.contains("fa-xmark")) isPopover = true;

    if (!isPopover) this.closePopOvers();

  }
}