<p-confirmDialog
  [key]="'cd'"
  class="delete-confirm"
  #cd
  [style]="{ width: '30rem' }"
>
  <ng-template pTemplate="header">
    <div class="modal-header text-center">
      <h4 class="modal-title w-100">Push to Google Sheet?</h4>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button danger btn"
        (click)="cd.accept()"
      >
        Push to Sheet
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn"
        (click)="cd.reject()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
<p-confirmDialog
  [key]="'deleteConfirm'"
  class="delete-confirm"
  #deleteConfirm
  [style]="{ width: '30rem' }"
>
  <ng-template pTemplate="header">
    <div class="modal-header text-center">
      <h4 class="modal-title w-100">Delete List?</h4>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="modal-footer">
      <button
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button danger btn"
        (click)="deleteConfirm.accept()"
      >
        Delete List
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn"
        (click)="deleteConfirm.reject()"
      >
        Cancel
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
<div class="skills-management-card content-card h-100 w-100 p-0">
  <div class="row fluid-content h-100">
    <div class="w-100 h-100 tabs-table">
      <div
        class="loading-screen d-flex align-content-center"
        *ngIf="tableLoading"
      >
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg === ''"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          *ngIf="errorRetrievingMsg !== ''"
        >
          <div
            class="alert alert-danger"
            role="alert"
            [innerHTML]="errorRetrievingMsg"
          ></div>
        </div>
      </div>
      <div
        class="loading-screen d-flex align-content-center"
        *ngIf="!tableLoading && allSkills.length === 0"
      >
        <div class="row flex-row flex-nowrap">
          <div class="col-12 p-4">
            You have added no skills yet.<span class="color-theme-base-medium"
              ><button
                (click)="openSkillsManagement('add')"
                class="inline"
                mdbBtn
                type="button"
                flat="true"
                size="lg"
                mdbWavesEffect
              >
                &nbsp;Add a new skill now.
              </button></span
            >
          </div>
        </div>
      </div>
      <div
        id="skillsManagement"
        class="management-card skills-table-card content-card"
        *ngIf="!tableLoading && allSkills.length"
      >
        <div class="row flex-row flex-nowrap">
          <div class="col-12 p-0">
            <div class="table-responsive">
              <table
                class="table table-striped"
                mdbTable
                #tableEl="mdbTable"
                small="true"
              >
                <colgroup class="skills">
                  <col
                    *ngFor="let head of headElements; let i = index"
                    [ngClass]="'col-' + sort_elements[i].head"
                  />
                </colgroup>
                <thead class="theme-base-medium-dark white-text">
                  <tr class="sorting">
                    <th
                      *ngFor="let head of headElements; let i = index"
                      scope="col"
                      [ngClass]="'col-' + sort_elements[i].head"
                    >
                      <span class="text-ellipsis" *ngIf="head !== 'action'">{{
                        head
                      }}</span>
                      <ng-template [ngIf]="sort_elements[i].type !== 'none'">
                        <mdb-icon
                          fas
                          *ngIf="sortBy !== sort_elements[i].head"
                          icon="sort"
                          class="sort-icon"
                          [mdbTooltip]="'Sort by ' + head"
                          placement="bottom"
                          (click)="
                            sortTable(
                              sort_elements[i].head,
                              'desc',
                              sort_elements[i].type
                            )
                          "
                        ></mdb-icon>
                        <mdb-icon
                          *ngIf="
                            sortBy == sort_elements[i].head &&
                            sortOrder == 'asc'
                          "
                          (click)="
                            sortTable(
                              sort_elements[i].head,
                              'desc',
                              sort_elements[i].type
                            )
                          "
                          fas
                          icon="caret-up"
                          class="sort-icon"
                        ></mdb-icon>
                        <mdb-icon
                          *ngIf="
                            sortBy == sort_elements[i].head &&
                            sortOrder == 'desc'
                          "
                          (click)="
                            sortTable(
                              sort_elements[i].head,
                              'asc',
                              sort_elements[i].type
                            )
                          "
                          fas
                          icon="caret-down"
                          class="sort-icon"
                        ></mdb-icon>
                      </ng-template>
                    </th>
                  </tr>
                  <tr class="event-filters">
                    <th
                      *ngFor="let head of headElements; let i = index"
                      scope="col"
                      [ngClass]="'col-' + sort_elements[i].head"
                    >
                      <ng-container *ngIf="filter_elements[i].filter">
                        <!--all filter types except select-->
                        <div
                          class="md-form md-filter-input mb-0 mt-0"
                          *ngIf="filter_elements[i].type !== 'select'"
                        >
                          <!--start text filter-->
                          <input
                            *ngIf="filter_elements[i].type === 'text'"
                            type="text"
                            [(ngModel)]="filterText[filter_elements[i].target]"
                            class="form-control text-ellipsis"
                            [id]="'filter-' + filter_elements[i].target"
                            [placeholder]="
                              'All ' + filter_elements[i].placeholder
                            "
                            (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                            mdbInput
                          />
                          <!--end text filter-->
                          <!--start number filter-->
                          <input
                            *ngIf="filter_elements[i].type === 'number'"
                            type="number"
                            [(ngModel)]="filterText[filter_elements[i].target]"
                            class="form-control"
                            [id]="'filter-' + filter_elements[i].target"
                            [placeholder]="
                              'All ' + filter_elements[i].placeholder
                            "
                            (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                            mdbInput
                          />
                          <!--end number filter-->
                          <fa-icon
                            class="cursor-pointer"
                            *ngIf="
                              filterText[filter_elements[i].target] !== '' &&
                              filterText[filter_elements[i].target] !== null
                            "
                            [icon]="faTimes"
                            (click)="resetFilter(i)"
                          ></fa-icon>
                        </div>
                        <!--end all filter types except select-->
                        <!--start access filter type-->
                        <div
                          class="md-form md-filter-input md-filter-select mb-0 mt-0"
                          *ngIf="filter_elements[i].type === 'select'"
                        >
                          <!--start access filter-->
                          <mdb-select-2
                            class="custom-arrow"
                            [id]="'filter-' + filter_elements[i].target"
                            [(ngModel)]="filterText[filter_elements[i].target]"
                            (ngModelChange)="
                              this.triggerFilterUpdate.next($event)
                            "
                          >
                            <mdb-select-option
                              *ngFor="let option of filter_elements[i].options"
                              [value]="option.value"
                              >{{ option.label }}</mdb-select-option
                            >
                          </mdb-select-2>
                          <!--end access filter-->
                          <fa-icon
                            class="cursor-pointer"
                            *ngIf="filterText[filter_elements[i].target] !== 0"
                            [icon]="faTimes"
                            (click)="resetFilter(i, 0)"
                          ></fa-icon>
                        </div>
                        <!--end access filter type-->
                      </ng-container>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="skills.length == 0">
                    <td class="action-icons">
                      <div class="empty-query">
                        <span>No skills for this query</span>
                      </div>
                    </td>
                    <td colspan="3"></td>
                  </tr>
                  <tr
                    mdbTableCol
                    *ngFor="let thisSkill of skills; let skilldex = index"
                  >
                    <td class="action-icons">
                      <div class="btn-group list-btns-sm btn-icons">
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="openSkillsManagement('update', thisSkill)"
                          mdbTooltip="Edit Skill"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faPencilAlt"></fa-icon>
                        </a>
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          [routerLink]="[
                            '/client',
                            clientCode,
                            'authoring',
                            'skills',
                            thisSkill.id
                          ]"
                          mdbTooltip="Edit Actions"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faTableList"></fa-icon>
                        </a>
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="cloneSkill(thisSkill)"
                          mdbTooltip="Clone Skill"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faClone"></fa-icon>
                        </a>
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="openSkillsManagement('archive', thisSkill)"
                          mdbTooltip="Archive Skill"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faArchive"></fa-icon>
                        </a>
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="updateSkillStatus(thisSkill)"
                          [mdbTooltip]="
                            thisSkill.status === 'draft'
                              ? 'Publish Skill'
                              : 'Unpublish Skill'
                          "
                          placement="bottom"
                        >
                          <fa-icon
                            *ngIf="thisSkill.status === 'draft'"
                            [icon]="faStar"
                          ></fa-icon>
                          <fa-icon
                            *ngIf="thisSkill.status === 'published'"
                            [icon]="faStarSolid"
                          ></fa-icon>
                        </a>
                        <a
                          size="lg"
                          mdbBtn
                          mdbWavesEffect
                          class="btn-flat btn-icon"
                          (click)="initiatePushToSheets(thisSkill)"
                          mdbTooltip="Push Skill to Sheets"
                          placement="bottom"
                        >
                          <fa-icon [icon]="faGoogleDrive"></fa-icon>
                        </a>
                      </div>
                    </td>
                    <td>{{ thisSkill.label }}</td>
                    <td>{{ thisSkill.organization }}</td>
                    <td>{{ thisSkill.target_user_label }}</td>
                    <td>{{ thisSkill.actions_count }}</td>
                    <td>
                      <ng-container *ngIf="!thisSkill.sheets_id">
                        Skill not pushed to sheets yet
                      </ng-container>
                      <ng-container *ngIf="thisSkill.sheets_id">
                        Skill pushed to sheets on {{ thisSkill.modified }}
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="!thisSkill.sheets_id">
                        N/A
                      </ng-container>
                      <ng-container *ngIf="thisSkill.sheets_id">
                        <a
                          href="https://docs.google.com/spreadsheets/d/{{
                            thisSkill.sheets_id
                          }}"
                          target="_blank"
                          >Link</a
                        >
                      </ng-container>
                    </td>
                    <td>
                      <ng-container *ngIf="!thisSkill.asset">
                        No Skill PDF
                      </ng-container>
                      <ng-container *ngIf="thisSkill.asset">
                        <span class="ellipsis-wrapper skill-media-pdf"
                          ><span class="text-ellipsis">{{
                            thisSkill.asset.name
                          }}</span></span
                        >
                      </ng-container>
                    </td>
                    <td>
                      <span class="lists-wrapper">
                        <button
                          (click)="managePhonemeLists(thisSkill)"
                          class="btn btn-link"
                        >
                          Add Word List +
                        </button>
                        <ng-container *ngIf="thisSkill.phoneme_lists.length">
                          <ul>
                            <li *ngFor="let phoneme of thisSkill.phoneme_lists">
                              {{ phoneme.phoneme_list.label }}
                              <div class="btn-group list-btns-sm btn-icons">
                                <a
                                  size="lg"
                                  mdbBtn
                                  mdbWavesEffect
                                  class="btn-flat btn-icon"
                                  (click)="
                                    initiateDeleteList(
                                      thisSkill,
                                      phoneme.phoneme_list
                                    )
                                  "
                                  mdbTooltip="Delete List"
                                  placement="bottom"
                                >
                                  <fa-icon [icon]="faTrashAlt"></fa-icon>
                                </a>
                              </div>
                            </li>
                          </ul>
                        </ng-container>
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="grey lighten-5 w-100" *ngIf="skills.length">
                  <tr>
                    <td colspan="12">
                      <p-paginator
                        #pagination
                        (onPageChange)="onPageChange($event)"
                        [first]="first"
                        [rows]="rows"
                        [totalRecords]="skillsLength"
                        [showFirstLastIcon]="true"
                        [showCurrentPageReport]="true"
                        currentPageReportTemplate="{first} - {last} of {totalRecords}"
                      ></p-paginator>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
