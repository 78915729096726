import { CoolLocalStorage } from '@angular-cool/storage';
import { Component, ViewChild, ViewEncapsulation } from '@angular/core';

import {
  faPencilAlt,
  faTrashAlt,
  faTimes,
  faArchive,
  faTableList,
  faStar as faStarSolid,
  faClone,
  faBullseye
} from "@fortawesome/free-solid-svg-icons";

import {
  faStar
} from "@fortawesome/free-regular-svg-icons";

import {
  faGoogleDrive
} from "@fortawesome/free-brands-svg-icons";

import { Subject, Subscription } from 'rxjs';
import { debounceTime } from "rxjs/operators";
import { CompoundFilterPipe } from '../../shared-modules/pipes/compound-filter.pipe';
import { SingleSortPipe } from '../../shared-modules/pipes/single-sort.pipe';
import { MDBModalRef, MDBModalService, MdbTableDirective } from 'ng-uikit-pro-standard';
import { AuthoringSkillsManagementComponent } from './modals/authoring-skills-management/authoring-skills-management.component';
import { NotificationsService } from 'src/app/services/utilities/notifications.service';
import { XrPlatformRestService } from 'src/app/services/rest/xr-platform/xr-platform-rest.service';
import { SharedDataService } from 'src/app/services/shared-data/shared-data.service';
import { ConfirmationService, ConfirmEventType } from 'primeng/api';
import { CloneSkillComponent } from './modals/clone-skill/clone-skill.component';
import { ManagePhonemeListsComponent } from './modals/manage-phoneme-lists/manage-phoneme-lists.component';

@Component({
  selector: 'app-authoring-skills',
  templateUrl: './authoring-skills.component.html',
  styleUrls: ['./authoring-skills.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CompoundFilterPipe, SingleSortPipe, ConfirmationService]
})
export class AuthoringSkillsComponent {
  private mdbTableData: MdbTableDirective;
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTableDirective, { static: false }) set Content(
    content: MdbTableDirective
  ) {
    if (content) {
      this.mdbTableData = content;
      this.mdbTableData.setDataSource(this.skills);
    }
  }

  @ViewChild("pagination") pagination: any;

  //persistent
  public token: string;
  public clientCode: string;

  //data
  public skills: any;
  public allSkills: any;
  public skillsLength: number = 0;
  public targetUserOptions: { value: string; label: string }[] = [{ value: '0', label: "Both" }];
  public targetUserFilter: { value: string; label: string }[] = [{ value: "both", label: "Both" }];
  public targetUsers: any;

  //user
  public user: any;

  //table management
  public tableLoading: boolean = true;

  //user feedback
  public errorRetrievingMsg: string = "";

  //subscriptions
  private shareDataSubscription: Subscription;

  //fa icons
  public faPencilAlt = faPencilAlt;
  public faTrashAlt = faTrashAlt;
  public faStar = faStar;
  public faStarSolid = faStarSolid;
  public faTimes = faTimes;
  public faArchive = faArchive;
  public faTableList = faTableList;
  public faGoogleDrive = faGoogleDrive;
  public faClone = faClone;

  //table elements
  headElements = [
    "action",
    "Skill Name",
    "Skill Org",
    "Target User",
    "# of Actions",
    "Status",
    "Sheets Link",
    "PDF UUID",
    "Word Lists"
  ];

  sort_elements = [
    {
      type: "none",
      head: "actions",
    },
    {
      type: "string",
      head: "name",
    },
    {
      type: "string",
      head: "organization",
    },
    {
      type: "string",
      head: "target_user_name",
    },
    {
      type: "number",
      head: "actions_count",
    },
    {
      type: "string",
      head: "status",
    },
    {
      type: "none",
      head: "sheets_id",
    },
    {
      type: "none",
      head: "asset_id",
    },
    {
      type: "none",
      head: "phoneme_lists",
    }
  ]
  sort = {
    direction: "asc",
    head: "name",
    type: "string",
  };
  public sortBy = "name";
  public sortOrder = "asc";

  //table paginiation
  public first: number = 0;
  public rows: number = 20;
  public page: number = 1;

  //filtering
  filter_elements = [
    {
      filter: false,
      type: "actions",
      target: "actions",
    },
    {
      filter: true,
      type: "text",
      target: "name",
      placeholder: "Name",
      default: "",
    },
    {
      filter: true,
      type: "text",
      target: "organization",
      placeholder: "Org",
      default: "",
    },
    {
      filter: true,
      type: "select",
      target: "target_user_name",
      options: this.targetUserFilter,
      default: "both",
    },
    {
      filter: true,
      type: "number",
      target: "actions_count",
      placeholder: "Actions",
      default: -1,
    },
    {
      filter: true,
      type: "select",
      target: "status",
      options: [
        {
          value: "all",
          label: "All Status Types",
        },
        {
          value: "published",
          label: "Published",
        },
        {
          value: "draft",
          label: "Draft",
        },
      ],
      default: "all",
    },
    {
      filter: false,
      type: "text",
      target: "sheets_id"
    },
    {
      filter: false,
      type: "text",
      target: "asset_id"
    },
    {
      filter: false,
      type: "text",
      target: "phoneme_lists"
    },
  ];

  //table filtering
  public filterText: {
    action: string;
    name: string;
    actions_count: string;
    status: string;
  } = {
      action: null,
      name: "",
      actions_count: "",
      status: "all",
    };

  //filter and sort detection
  public triggerFilterUpdate: Subject<any> = new Subject();

  //for modals
  public skillsFrame: MDBModalRef;
  public cloneFrame: MDBModalRef;
  private modalOptions = {
    backdrop: "static",
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: "modal-dialog-centered",
    containerClass: "",
    animated: true,
    data: {},
  };

  constructor(
    private coolLocalStorage: CoolLocalStorage,
    private _xrPlatformRestService: XrPlatformRestService,
    private compoundFilterPipe: CompoundFilterPipe,
    private singleSortPipe: SingleSortPipe,
    private modalService: MDBModalService,
    private _notificationService: NotificationsService,
    private _sharedDataService: SharedDataService,
    private confirmationService: ConfirmationService
  ) { }

  ngOnInit(): void {
    this.retrieveToken();
    this.retrieveClientCode();
    this.retrieveUser();
    this.retrieveData();

    this.triggerFilterUpdate
      .pipe(debounceTime(350))
      .subscribe(() => this.updateFilteredEvents(true));

    this.shareDataSubscription =
      this._sharedDataService.sharedDataComm$.subscribe((incoming: any) => {
        if (incoming.type === "buttonAction") {
          if (
            incoming.data.buttonAction !== undefined &&
            incoming.data.buttonAction === "add_skill"
          ) {
            this.openSkillsManagement("add");
          }
        }
      });
  }

  private retrieveToken() {
    this.token = this.coolLocalStorage.getItem("admin_panel_jwt");
  }

  private retrieveUser() {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
  }

  private retrieveClientCode() {
    this.clientCode = this.coolLocalStorage.getItem("admin_panel_clientcode");
  }

  private async retrieveData() {
    //retrieve data
    let retrieveSkills = await this.retrieveSkills().catch((error) => {
      this.errorRetrievingMsg = `There was an error while retrieving skills. Please try again or contact support`;
    });

    this.targetUsers = await this.retrieveTargetUsers().catch((error) => {
      this.errorRetrievingMsg = `There was an error while retrieving target users. Please try again or contact support`;
    });

    this.targetUserOptions = this.targetUsers.target_users.map((user) => {
      return {
        value: user.value.toString(),
        label: user.label,
      };
    });
    this.targetUserFilter = this.targetUsers.target_users.map((user) => {
      return {
        value: user.name,
        label: user.label,
      };
    });

    this.filter_elements = this.filter_elements.map((element) => {
      if (element.target === "target_user_name") {
        element.options = this.targetUserFilter;
      }

      return element;
    });

    console.log("this.filter_elements", this.filter_elements);

    this.skills = retrieveSkills.skills.map((skill) => {
      skill.target_user_label = this.targetUserOptions.find((user) => parseInt(user.value) === skill.target_user).label;
      skill.target_user_name = this.targetUsers.target_users.find((user) => parseInt(user.value) === skill.target_user).name;
      return skill;
    }
    );

    this.allSkills = retrieveSkills.skills;
    this.tableLoading = false;
    this.updateFilteredEvents();

    //also let menu know that we're done loading
    let toSend = {
      type: "pageLoaded",
      data: {
        pageLoaded: true,
      },
    };

    this._sharedDataService.sendSharedData(toSend);
  }

  private retrieveSkills() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/skills`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    );

    return eventRetrieve.toPromise();
  }

  private retrieveTargetUsers() {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const getOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/skills/target-users`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "get",
      {},
      getOptions
    );

    return eventRetrieve.toPromise();
  }

  updateFilteredEvents(resetPage?: boolean) {

    if (resetPage === undefined) resetPage = false;

    this.skills = this.compoundFilterPipe.transform(
      this.allSkills,
      {
        name: {
          text: this.filterText.name,
          type: 'text'
        },
        actions_count: {
          text: this.filterText.actions_count,
          type: 'number'
        },
        status: {
          text: this.filterText.status,
          type: 'status'
        }
      }
    )

    this.skills = this.singleSortPipe.transform(this.skills, this.sort.direction, this.sort.head, this.sort.type);

    this.skillsLength = this.skills.length;

    if (resetPage) {
      this.page = 1;
      this.pagination.changePage(0);
    }

    this.skills = this.skills.slice((this.page - 1) * this.rows, (this.page - 1) * this.rows + this.rows);
  }

  /**
   * Custom table sorting based on current head key
   * @param head
   * @param direction
   */
  public sortTable(head, direction, type) {
    this.sortBy = head;
    this.sortOrder = direction;

    this.sort.head = head;
    this.sort.direction = direction;
    this.sort.type = type;

    this.updateFilteredEvents();
  }

  onPageChange(event) {

    this.page = event.page + 1;
    this.updateFilteredEvents();

  }

  /**
   * Reset specific filter ngModel filterText[filter_elements[i].target]
   * @param index
   */
  public resetFilter(index, value?) {
    if (value === undefined) value = "";

    this.filterText[this.filter_elements[index].target] = value;

    this.updateFilteredEvents();
  }

  public openSkillsManagement(action, thisSkill?) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-experiences-container",
      class: "modal-dialog modal-dialog-centered modal-full-height modal-right",
    };

    this.modalOptions.data = {
      user: this.user,
      skills: this.skills,
      targetSkill: thisSkill,
      action: action,
      targetUserOptions: this.targetUserOptions,
    };

    this.skillsFrame = this.modalService.show(AuthoringSkillsManagementComponent, this.modalOptions);

    this.skillsFrame.content.outgoing.subscribe((result) => {

      console.log("result in skills frame", result);

      if (result.action !== undefined) {
        let actionAdj = action.replace(/e$/, "");
        this._notificationService.successNotification(
          `Skill successfully ${actionAdj}ed`
        );

        switch (result.action) {
          case "add":
            this.skills.push(result.skill);
            break;
          case "update":
            let index = this.skills.findIndex((skill) => skill.id === result.skill.id);

            result.skill.target_user_label = this.targetUserOptions.find((user) => parseInt(user.value) === result.skill.target_user).label;
            result.skill.target_user_name = this.targetUsers.target_users.find((user) => parseInt(user.value) === result.skill.target_user).name;
            result.skill.target_user = parseInt(result.skill.target_user);

            this.skills[index] = result.skill;

            break;
          case "archive":
            this.skills = this.skills.filter((skill) => skill.id !== result.skill.id);
            break;
        }

        this.allSkills = this.skills;

        this.updateFilteredEvents();

      }

    });
  }

  public cloneSkill(skill) {
    this.modalOptions.containerClass = "";
    this.modalOptions.class = "modal-dialog modal-dialog-centered";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "delete-asset-container",
    };

    this.modalOptions.data = {
      targetSkill: skill,
      action: "clone",
      title: `Clone Skill?`,
      message: `Clone ${skill.label}?`,
      button: `Clone`,
    };

    this.cloneFrame = this.modalService.show(
      CloneSkillComponent,
      this.modalOptions
    );

    this.cloneFrame.content.outgoing.subscribe((result: any) => {

      if (result.action !== undefined && result.action === "clone") {
        let toastMsg = `${skill.label} has been cloned`;
        this._notificationService.successNotification(toastMsg);
        this.skills.push(result.changedData);
      }
    });
  }

  //open modeal managePhonemeLists
  public managePhonemeLists(skill) {
    this.modalOptions.containerClass = "";
    this.modalOptions = {
      ...this.modalOptions,
      containerClass: "manage-phonemes-container",
      class: this.modalOptions.class + " modal-full-height modal-full-width modal-right",
    };

    this.modalOptions.data = {
      user: this.user,
      skillID: skill.id,
    };

    this.skillsFrame = this.modalService.show(ManagePhonemeListsComponent, this.modalOptions);

    this.skillsFrame.content.outgoing.subscribe((result) => {

      console.log("result in skills frame", result);

      if (result.action !== undefined) {
        let actionAdj = "phoneme lists";
        this._notificationService.successNotification(
          `Word List successfully ${actionAdj}ed`
        );

        let index = this.skills.findIndex((thisSkill) => skill.id === thisSkill.id);

        this.skills[index].phoneme_lists.push(result.data);

        this.allSkills = this.skills;
        this.updateFilteredEvents();

      }

    });
  }

  public initiatePushToSheets(thisSkill) {
    this.confirmationService.confirm({
      message: `Do you want to push ${thisSkill.label} to Google Sheets?`,
      icon: 'pi pi-exclamation-triangle',
      key: "cd",
      accept: () => {
        this.pushSkillToSheets(thisSkill);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            console.log("rejected");
            break;
          case ConfirmEventType.CANCEL:
            console.log("confirmed");
            break;
        }
      }
    });
  }

  public initiateDeleteList(skill, list) {
    this.confirmationService.confirm({
      message: `Do you want to delete the ${list.label} list?`,
      icon: 'pi pi-exclamation-triangle',
      key: "deleteConfirm",
      accept: () => {
        this.deleteList(skill, list);
      },
      reject: (type) => {
        switch (type) {
          case ConfirmEventType.REJECT:
            console.log("rejected");
            break;
          case ConfirmEventType.CANCEL:
            console.log("confirmed");
            break;
        }
      }
    });
  }

  public deleteList(skill, list) {

    let notification = this._notificationService.savingNotification("Deleting list...");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/skill/${skill.id}/phoneme-list/${list.id}/delete`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "put",
      {},
      postOptions
    );

    eventRetrieve.subscribe((response) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.successNotification("List deleted successfully");

      let index = this.skills.findIndex((thisSkill) => thisSkill.id === skill.id);

      this.skills[index].phoneme_lists = this.skills[index].phoneme_lists.filter((thisList) => thisList.id !== list.id);

      this.allSkills = this.skills;
      this.updateFilteredEvents();

    }, (error) => {
      this._notificationService.errorNotification("There was an error deleting list");
    });

  }

  public pushSkillToSheets(thisSkill) {
    let notification = this._notificationService.savingNotification("Pushing skill to sheets...");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/actions/${thisSkill.id}/to-sheets`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "put",
      {},
      postOptions
    );

    eventRetrieve.subscribe((response) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.successNotification("Skill pushed to sheets successfully");

      let index = this.skills.findIndex((skill) => skill.id === thisSkill.id);

      if (!thisSkill.sheets_id) this.skills[index].sheets_id = response.sheets_id;
      this.skills[index].modified = response.modified;

      this.skills = this.skills.map((skill) => {
        if (skill.id === thisSkill.id) {
          if (skill.sheets_id === undefined) skill.sheets_id = response.file_id;
          skill.modified = response.modified;
          console.log("skill in map", skill);
        }

        return skill;
      })

      console.log("skills after push", JSON.parse(JSON.stringify(this.skills)));

      this.allSkills = this.skills;
      this.updateFilteredEvents();

    }, (error) => {
      this._notificationService.errorNotification("There was an error pushing skill to sheets");
    });

  }

  public updateSkillStatus(skill) {

    if (skill.status === "published") skill.status = "draft";
    else skill.status = "published";

    let notification = this._notificationService.savingNotification("Updating skill status...");

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.token,
    };

    const postOptions = {
      headers: headers,
    };

    let targetURL = `/authoring/skill/${skill.id}`;

    let eventRetrieve = this._xrPlatformRestService.restfulAPIQuery(
      targetURL,
      "put",
      {
        status: skill.status
      },
      postOptions
    );

    eventRetrieve.subscribe((response) => {
      this._notificationService.clearNotification(notification);
      this._notificationService.successNotification("Skill status updated successfully");

      let index = this.skills.findIndex((skill) => skill.id === response.id);
      this.skills[index] = response;
      this.allSkills = this.skills;
      this.updateFilteredEvents();

    }, (error) => {
      this._notificationService.errorNotification("There was an error updating skill status");
    });

  }

}
