<div class="modal-content-wrapper" (click)="modalClick($event)">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100">
      <span>Add Word List</span>
    </h4>
    <button
      *ngIf="isClean"
      type="button"
      class="close"
      data-dismiss="modal"
      aria-label="Close"
      (click)="closeModal()"
      mdbTooltip="Close"
      placement="bottom"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <button
      *ngIf="!isClean"
      type="button"
      class="close cancel-alert"
      #popOverTriggerTop="bs-mdbPopover"
      id="cancelWarningCloseX"
      triggers="click"
      mdbPopoverHeader=""
      [mdbPopover]="cancelWarning"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
  <div class="modal-body modal-fluid-content p-0">
    <div class="container-overflow-auto">
      <div class="row info-row">
        <div class="content info-padded w-100">
          <div id="uploadCSV">
            <form>
              <div class="file-field md-form">
                <div mdbBtn class="theme-modal-button btn">
                  <span>Upload CSV</span>
                  <input
                    mdbFileSelect
                    type="file"
                    #fileImportInput
                    name="csvFileUpload"
                    label="Upload CSV"
                    id="csvFileUpload"
                    (change)="fileChangeListener($event)"
                    accept=".csv"
                    [disabled]="formLoading"
                  />
                </div>
                <div class="file-path-wrapper">
                  <input
                    class="file-path"
                    type="text"
                    placeholder="Your CSV File"
                    disabled="true"
                  />
                </div>
              </div>
            </form>
          </div>
          <p>Add a word list to this skill.</p>
          <div class="form-wrapper search-or-create">
            <div
              *ngIf="formLoading"
              class="loading-screen d-flex align-items-center h-100 justify-content-center"
            >
              <div class="d-flex justify-content-center">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <form
              *ngIf="!formLoading"
              [formGroup]="wordListForm"
              autocomplete="off"
            >
              <div class="new-group">
                <div class="md-form group-name">
                  <input
                    type="text"
                    id="groupingForm-group-name"
                    class="form-control"
                    formControlName="list_name"
                    mdbInput
                    mdbValidate
                  />
                  <label
                    class="active-label active"
                    for="groupingForm-group-name"
                    >List Name</label
                  >
                  <mdb-error
                    *ngIf="
                      list_name.invalid &&
                      (list_name.dirty || list_name.touched)
                    "
                  >
                    List name required
                  </mdb-error>
                </div>
                <div class="md-form group-description">
                  <input
                    type="text"
                    id="groupingForm-group-description"
                    class="form-control"
                    formControlName="list_description"
                    mdbInput
                    mdbValidate
                  />
                  <label
                    class="active-label active"
                    for="groupingForm-group-description"
                    >List Description</label
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        *ngIf="formLoading"
        class="loading-screen d-flex align-items-center h-100 justify-content-center"
      >
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <form
        [formGroup]="managePhonemesForm"
        class="edit-form repeater-form"
        *ngIf="!formLoading"
      >
        <div
          class="repeater"
          *ngFor="let PhonemeFields of managePhonemeFieldsGroups; let i = index"
        >
          <div class="d-flex flexed repeater-item" [formGroup]="PhonemeFields">
            <div class="btn-group list-btns-sm btn-icons">
              <button
                [disabled]="managePhonemeFieldsGroups[i].status === 'INVALID'"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon add"
                (click)="addPhonemeFields()"
              >
                <fa-icon [icon]="faPlusSquare"></fa-icon>
              </button>
              <button
                [ngClass]="{ hidden: i === 0 }"
                mdbBtn
                mdbWavesEffect
                class="btn-flat btn-icon remove"
                (click)="removePhonemeField(i)"
              >
                <fa-icon [icon]="faMinus"></fa-icon>
              </button>
              <!-- add the following form fields, none are required:
               - full_word: text
                - phoneme_1: text
                - phoneme_type_1: text
                - phoneme_spelling_1: text
                - is_unpredictable_1: text
                - has_silent_e_1: text
                - phoneme_2: text
                - phoneme_type_2: text
                - phoneme_spelling_2: text
                - is_unpredictable_2: text
                - has_silent_e_2: text
                - phoneme_3: text
                - phoneme_type_3: text
                - phoneme_spelling_3: text
                - is_unpredictable_3: text
                - has_silent_e_3: text
                - phoneme_4: text
                - phoneme_type_4: text
                - phoneme_spelling_4: text
                - is_unpredictable_4: text
                - has_silent_e_4: text
               -->
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-full_word"
                  class="form-control"
                  formControlName="full_word"
                  mdbInput
                  mdbValidate
                />
                <label class="active-label active" for="groupingForm-full_word"
                  >Full Word</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_1"
                  class="form-control"
                  formControlName="phoneme_1"
                  mdbInput
                  mdbValidate
                />
                <label class="active-label active" for="groupingForm-phoneme_1"
                  >Phoneme 1</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_type_1"
                  class="form-control"
                  formControlName="phoneme_type_1"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_type_1"
                  >Phoneme 1 Type</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_spelling_1"
                  class="form-control"
                  formControlName="phoneme_spelling_1"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_spelling_1"
                  >Phoneme 1 Spelling</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-is_unpredictable_1"
                  class="form-control"
                  formControlName="is_unpredictable_1"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-is_unpredictable_1"
                  >Is Unpredictable 1</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-has_silent_e_1"
                  class="form-control"
                  formControlName="has_silent_e_1"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-has_silent_e_1"
                  >Has Silent-E 1</label
                >
              </div>

              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_2"
                  class="form-control"
                  formControlName="phoneme_2"
                  mdbInput
                  mdbValidate
                />
                <label class="active-label active" for="groupingForm-phoneme_2"
                  >Phoneme 2</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_type_2"
                  class="form-control"
                  formControlName="phoneme_type_2"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_type_2"
                  >Phoneme 2 Type</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_spelling_2"
                  class="form-control"
                  formControlName="phoneme_spelling_2"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_spelling_2"
                  >Phoneme 2 Spelling</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-is_unpredictable_2"
                  class="form-control"
                  formControlName="is_unpredictable_2"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-is_unpredictable_2"
                  >Is Unpredictable 2</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-has_silent_e_2"
                  class="form-control"
                  formControlName="has_silent_e_2"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-has_silent_e_2"
                  >Has Silent-E 2</label
                >
              </div>

              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_3"
                  class="form-control"
                  formControlName="phoneme_3"
                  mdbInput
                  mdbValidate
                />
                <label class="active-label active" for="groupingForm-phoneme_3"
                  >Phoneme 3</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_type_3"
                  class="form-control"
                  formControlName="phoneme_type_3"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_type_3"
                  >Phoneme 3 Type</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_spelling_3"
                  class="form-control"
                  formControlName="phoneme_spelling_3"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_spelling_3"
                  >Phoneme 3 Spelling</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-is_unpredictable_3"
                  class="form-control"
                  formControlName="is_unpredictable_3"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-is_unpredictable_3"
                  >Is Unpredictable 3</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-has_silent_e_3"
                  class="form-control"
                  formControlName="has_silent_e_3"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-has_silent_e_3"
                  >Has Silent-E 3</label
                >
              </div>

              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_4"
                  class="form-control"
                  formControlName="phoneme_4"
                  mdbInput
                  mdbValidate
                />
                <label class="active-label active" for="groupingForm-phoneme_4"
                  >Phoneme 4</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_type_4"
                  class="form-control"
                  formControlName="phoneme_type_4"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_type_4"
                  >Phoneme 4 Type</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_spelling_4"
                  class="form-control"
                  formControlName="phoneme_spelling_4"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_spelling_4"
                  >Phoneme 4 Spelling</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-is_unpredictable_4"
                  class="form-control"
                  formControlName="is_unpredictable_4"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-is_unpredictable_4"
                  >Is Unpredictable 4</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-has_silent_e_4"
                  class="form-control"
                  formControlName="has_silent_e_4"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-has_silent_e_4"
                  >Has Silent-E 4</label
                >
              </div>

              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_5"
                  class="form-control"
                  formControlName="phoneme_5"
                  mdbInput
                  mdbValidate
                />
                <label class="active-label active" for="groupingForm-phoneme_5"
                  >Phoneme 5</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_type_5"
                  class="form-control"
                  formControlName="phoneme_type_5"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_type_5"
                  >Phoneme 5 Type</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-phoneme_spelling_5"
                  class="form-control"
                  formControlName="phoneme_spelling_5"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-phoneme_spelling_5"
                  >Phoneme 5 Spelling</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-is_unpredictable_5"
                  class="form-control"
                  formControlName="is_unpredictable_5"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-is_unpredictable_5"
                  >Is Unpredictable 5</label
                >
              </div>
              <div class="md-form">
                <input
                  type="text"
                  id="groupingForm-has_silent_e_5"
                  class="form-control"
                  formControlName="has_silent_e_5"
                  mdbInput
                  mdbValidate
                />
                <label
                  class="active-label active"
                  for="groupingForm-has_silent_e_5"
                  >Has Silent-E 5</label
                >
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal-footer">
    <span
      *ngIf="!formLoading"
      id="mainEventAction"
      class="button-wrapper"
      triggers="focus"
      mdbPopoverHeader=""
      [mdbPopover]="
        (!managePhonemesForm.valid || !managePhonemesForm.valid) &&
        formState !== 'processing'
          ? mainEventAction
          : null
      "
      (shown)="showingWarning()"
    >
      <button
        [disabled]="
          !managePhonemesForm.valid ||
          !wordListForm.valid ||
          isClean ||
          formState === 'processing'
        "
        mdbBtn
        type="button"
        mdbWavesEffect
        class="theme-modal-button btn"
        mdbWavesEffect
        (click)="addWordList()"
      >
        {{ btnLabel.main }}
      </button>
    </span>
    <button
      *ngIf="isClean"
      mdbBtn
      mdbWavesEffect
      type="button"
      color="link"
      class="theme-modal-cancel-button btn"
      (click)="closeModal()"
    >
      Close
    </button>
    <button
      *ngIf="!isClean"
      mdbBtn
      mdbWavesEffect
      #popOverTrigger="bs-mdbPopover"
      id="cancelWarning"
      type="button"
      color="link"
      class="theme-modal-cancel-button btn cancel-alert"
      mdbPopoverHeader=""
      triggers="click"
      [mdbPopover]="cancelWarning"
    >
      Cancel
    </button>
  </div>
</div>

<ng-template #mainEventAction
  ><div class="custom-pop error-pop">
    <p>Please review issues above.</p>
  </div></ng-template
>

<ng-template #cancelWarning
  ><div class="custom-pop error-pop wider near-right">
    <p class="semi-bold">
      Warning! All updates on this word list will be lost.
    </p>
    <div class="button-wrapper">
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-button btn warning-stay"
        data-dismiss="modal"
      >
        Back to manage skills
      </button>
      <button
        mdbBtn
        mdbWavesEffect
        type="button"
        color="link"
        class="theme-modal-cancel-button btn warning-leave"
        data-dismiss="modal"
        (click)="manageSystemUsersFrame.hide()"
      >
        Continue Cancel
      </button>
    </div>
  </div></ng-template
>
