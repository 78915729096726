import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { CoolLocalStorage } from "@angular-cool/storage";

@Injectable({
  providedIn: "root",
})
export class ClientManagementService {
  private instance: string = this.getInstance();
  private user: any = null;
  public user_role_id: number = 0;

  constructor(
    private router: Router,
    private coolLocalStorage: CoolLocalStorage
  ) {}

  public getClientSettings(teamID, route?: string) {
    this.user = this.coolLocalStorage.getObject("admin_panel_userinfo");
    if (this.user !== null) this.user_role_id = this.user.role_type_id;

    let settings = {
      experienceOptions: {
        buildings: {
          properties: [],
          type: "select",
        },
      },
      experienceMetaOptions: {
        logo: {
          type: "media",
          label: "Logo",
          status: false,
          mediaType: "image",
        },
        video: {
          type: "media",
          label: "Video",
          status: false,
          mediaType: "video",
        },
        pdf: {
          type: "media",
          label: "PDF",
          status: false,
          mediaType: "pdf",
        },
        initial_video: {
          type: "text",
          label: "Initial Video",
          status: false,
        },
        initial_pdf: {
          type: "text",
          label: "Initial PDF",
          status: false,
        },
        photon_id: {
          type: "text",
          label: "Photon ID",
          status: false,
        },
        display_name: {
          type: "text",
          label: "Display Name",
          status: false,
        },
        chimera_environment: {
          type: "text",
          label: "Chimera Enviornment",
          status: false,
        },
        protocol: {
          type: "select",
          label: "",
          status: false,
          selectValues: [],
        },
        number_of_seats: {
          type: "select",
          label: "",
          status: false,
          selectValues: [],
        },
        event_type: {
          type: "select",
          label: "",
          status: false,
          selectValues: [],
        },
      },
    };

    if (route === undefined) {
      route = "";
    }

    switch (teamID) {
      case 3:
        switch (this.instance) {
          case "admindev":
            settings.experienceOptions.buildings = {
              properties: [
                {
                  label: "Technology Center",
                  value: "technology_center",
                },
                {
                  label: "Business Center",
                  value: "business_center",
                },
                {
                  label: "None",
                  value: "none",
                },
              ],
              type: "select",
            };
            settings.experienceMetaOptions.chimera_environment.status = true;
            settings.experienceMetaOptions.display_name.status = true;
            settings.experienceMetaOptions.event_type.status = true;
            settings.experienceMetaOptions.event_type.label = "Type of Room";
            settings.experienceMetaOptions.event_type.selectValues = [
              {
                value: "normal",
                label: "Normal",
              },
              {
                value: "floortolist",
                label: "Floor To List",
              },
            ];
            settings.experienceMetaOptions.initial_pdf.status = true;
            settings.experienceMetaOptions.initial_video.status = true;
            settings.experienceMetaOptions.photon_id.status = true;
            settings.experienceMetaOptions.logo.status = true;
            settings.experienceMetaOptions.pdf.status = true;
            settings.experienceMetaOptions.video.status = true;
            settings = this.setUpSeating(settings);
            break;
        }
        break;
      case 4:
        switch (this.instance) {
          case "adept":
          case "adeptdev":
            settings.experienceMetaOptions.event_type.status = true;
            settings.experienceMetaOptions.event_type.label = "Type of Room";
            settings.experienceMetaOptions.event_type.selectValues = [
              {
                value: "normal",
                label: "Normal",
              },
              {
                value: "floortolist",
                label: "Floor To List",
              },
            ];
            break;
          case "admindev":
            settings.experienceMetaOptions.logo.status = true;
            settings.experienceMetaOptions.pdf.status = true;
            settings.experienceMetaOptions.video.status = true;
            break;
        }
        break;
      case 5:
        switch (this.instance) {
          case "foretell":
          case "foretellprod":
            settings = this.setUpSeating(settings);
            break;
        }
        break;
      case 6:
        switch (this.instance) {
          case "foretellprod":
            settings.experienceMetaOptions.logo.status = true;
            break;
        }
        break;
      case 7:
        switch (this.instance) {
          case "foretell":
            settings.experienceMetaOptions.logo.status = true;
            break;
        }
        break;
      case 8:
        switch (this.instance) {
          case "foretell":
          case "foretellprod":
            settings = this.setUpSeating(settings);
            break;
        }
        break;
      case 9:
        switch (this.instance) {
          case "foretell":
          case "foretellprod":
            settings.experienceMetaOptions.logo.status = true;
            break;
        }
        break;
      case 10:
        switch (this.instance) {
          case "pagonivr":
            settings.experienceMetaOptions.initial_video.status = true;
            settings.experienceMetaOptions.initial_pdf.status = true;
            settings.experienceMetaOptions.display_name.status = true;
            settings.experienceMetaOptions.chimera_environment.status = true;
            settings.experienceMetaOptions.photon_id.status = true;
            break;
        }
        break;
      case 21:
        switch (this.instance) {
          case "adept":
            settings.experienceMetaOptions.event_type.status = true;
            settings.experienceMetaOptions.event_type.label = "Type of Room";
            settings.experienceMetaOptions.event_type.selectValues = [
              {
                value: "normal",
                label: "Normal",
              },
              {
                value: "floortolist",
                label: "Floor To List",
              },
            ];
            break;
        }
        break;
      case 27:
        switch (this.instance) {
          case "adept":
            settings.experienceMetaOptions.event_type.status = true;
            settings.experienceMetaOptions.event_type.label = "Type of Room";
            settings.experienceMetaOptions.event_type.selectValues = [
              {
                value: "normal",
                label: "Normal",
              },
              {
                value: "floortolist",
                label: "Floor To List",
              },
            ];
            break;
        }
        break;
      case 42:
      case 43:
      case 45:
        settings = this.setUpSeating(settings);
        break;
      case 33:
        if (this.instance === "adeptdev")
          settings = this.setUpSeating(settings);
        break;
      case 23:
      case 28:
      case 41:
        settings = this.setUpProtocol(settings);
        settings = this.setUpSeating(settings);
        break;
      case 38:
        settings.experienceMetaOptions.logo.status = true;
        break;
    }

    if (teamID > 38) settings.experienceMetaOptions.logo.status = true;

    return settings;
  }

  private setUpProtocol(settings) {
    settings.experienceMetaOptions.protocol.status = true;
    settings.experienceMetaOptions.protocol.label = "Protocol";
    settings.experienceMetaOptions.protocol.selectValues = [
      {
        value: 0,
        label: "Auto",
      },
      {
        value: 1,
        label: "UDP",
      },
      {
        value: 2,
        label: "WSS",
      },
    ];

    return settings;
  }

  private setUpSeating(settings) {
    settings.experienceMetaOptions.number_of_seats.status = true;
    settings.experienceMetaOptions.number_of_seats.label = "Number of Seats";
    settings.experienceMetaOptions.logo.status = true;
    settings.experienceMetaOptions.number_of_seats.selectValues = [
      {
        value: 2,
        label: "2",
      },
      {
        value: 3,
        label: "3",
      },
      {
        value: 4,
        label: "4",
      },
      {
        value: 5,
        label: "5",
      },
      {
        value: 6,
        label: "6",
      },
      {
        value: 7,
        label: "7",
      },
      {
        value: 8,
        label: "8",
      },
      {
        value: 9,
        label: "9",
      },
      {
        value: 10,
        label: "10",
      },
      {
        value: 11,
        label: "11",
      },
      {
        value: 12,
        label: "12",
      },
      {
        value: 13,
        label: "13",
      },
      {
        value: 14,
        label: "14",
      },
      {
        value: 15,
        label: "15",
      },
    ];

    return settings;
  }

  public getRESTurl(v?: string) {
    if (v === undefined) v = "v1";

    let restURL = `https://xrplatform-prod.adeptreality.com/api/${v}`;
    let subdomain = location.origin;

    if (subdomain.indexOf("admin.immersivehealthgroup.") !== -1) {
      restURL = `https://apb-prod.immersivehealthgroup.com/api/${v}`;
    } else if (subdomain.indexOf("ihgadmindev.") !== -1) {
      //does not currently have a backend
      restURL = `https://ihgdev.xrplatform.io/api/${v}`;
    } else if (subdomain.indexOf("legacy-dev-admin.foretellreality.") !== -1) {
      restURL = `https://xrplatform-foretell-dev.foretellreality.com/api/${v}`;
    } else if (
      subdomain.indexOf("legacy-stage-admin.foretellreality.") !== -1
    ) {
      restURL = `https://stage-foretell.xrplatform.io/api/${v}`;
    } else if (
      subdomain.indexOf("dev-admin.foretellreality.") !== -1 ||
      subdomain.indexOf("dev-admin.adeptreality.") !== -1
    ) {
      restURL = `https://xrplatform-dev.adeptreality.com/api/${v}`;
    } else if (
      subdomain.indexOf("stage-admin.foretellreality.") !== -1 ||
      subdomain.indexOf("stage-admin.adeptreality.") !== -1
    ) {
      restURL = `https://xrplatform-stage.adeptreality.com/api/${v}`;
    } else if (subdomain.indexOf("staging-admin.d6vr.io") !== -1) {
      restURL = `https://d6vr-staging.xrplatform.io/api/${v}`;
    } else if (subdomain.indexOf("dev-admin.d6vr.io") !== -1) {
      restURL = `https://dev-admin-backend.d6vr.io/api/${v}`;
    } else if (subdomain.indexOf("admin.d6vr.io") !== -1) {
      restURL = `https://prod-admin-backend.d6vr.io/api/${v}`;
    } else if (subdomain.indexOf("admin-dev.glimpsegroup.") !== -1) {
      restURL = `https://admin-dev-backend.adeptreality.com/api/${v}`;
    } else if (subdomain.indexOf("admin-mirror.adeptreality.") !== -1) {
      restURL = `https://admin-mirror-backend.adeptreality.com/api/${v}`;
    } else if (subdomain.indexOf("admin-stage.glimpsegroup.") !== -1) {
      restURL = `https://staging-admin.adeptreality.com/api/${v}`;
    } else if (subdomain.indexOf("admin-dev-merge.glimpsegroup.") !== -1) {
      restURL = `https://xrp.early-adopter.com/api/${v}`;
    } else if (subdomain.indexOf("https://admin.pagonivr.com") !== -1) {
      restURL = `https://admin-backend.pagonivr.com/api/${v}`;
    } else if (
      subdomain.indexOf("https://vivo-panel.glimpsegroup.com") !== -1
    ) {
      restURL = `https://vivo.adeptreality.com/vivo/${v}`;
    } else if (
      subdomain.indexOf("admin.buildtest") !== -1
    ) {
      restURL = `https://xrplatform-prod.adeptreality.com/api/${v}`;
    } else if (subdomain.indexOf("localhost") !== -1) {
      //restURL = `https://admin-dev-backend.adeptreality.com/api/${v}`
      //restURL = `https://admin-mirror-backend.adeptreality.com/api/${v}`;
      //restURL = `https://staging-admin.adeptreality.com/api/${v}`;
      restURL = `https://xrplatform-stage.adeptreality.com/api/${v}`;
    }
    return restURL;
  }

  public getVendorURL(vendor) {
    let url = "";

    switch (vendor) {
      case "userDataSystem":
        url = "https://user-data-dev.glimpsegroup.com";
        break;
    }

    return url;
  }

  public getInstance() {
    let instance = "adept";
    let subdomain = location.origin;

    if (subdomain.indexOf("admin.immersivehealthgroup.") !== -1) {
      instance = "ihg";
    } else if (subdomain.indexOf("legacy-dev-admin.foretellreality.") !== -1) {
      instance = "foretell";
    } else if (
      subdomain.indexOf("legacy-admin.foretellreality.") !== -1 ||
      subdomain.indexOf("legacy-stage-admin.foretellreality.") !== -1
    ) {
      instance = "foretellprod";
    } else if (subdomain.indexOf("dev-admin.foretellreality.") !== -1) {
      instance = "adeptdev";
    } else if (
      subdomain.indexOf("staging-admin.d6vr.") !== -1 ||
      subdomain.indexOf("dev-admin.d6vr.") !== -1 ||
      subdomain.indexOf("admin.d6vr.") !== -1
    ) {
      instance = "d6vr";
    } else if (subdomain.indexOf("admin.pagonivr.") !== -1) {
      instance = "pagonivr";
    } else if (subdomain.indexOf("vivo-panel.glimpsegroup.") !== -1) {
      instance = "vivo";
    } else if (
      subdomain.indexOf("admin-dev.glimpsegroup.") !== -1 ||
      subdomain.indexOf("admin-stage.glimpsegroup.") !== -1
    ) {
      instance = "admindev";
    } else if (subdomain.indexOf("localhost") !== -1) {
      instance = "admindev";
    }

    return instance;
  }
}
